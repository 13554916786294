<template>
  <v-app>
    <!-- INSERÇÃO DOS COMPONENTES VIA ROTA -->
      <router-view :url="url" :token="token"></router-view>
      <!-- END -->
  </v-app>
</template>

<script>

import PFCookie from 'js-cookie'

export default {
  name: 'App',

  data(){
    return{
      online: false,
      username: PFCookie.get('_username'),
      userid: PFCookie.get('_userid'),
      useraccess: PFCookie.get('_useraccess'),
      url: process.env.VUE_APP_URL,
      token: PFCookie.get('_pf_token'),
      on: PFCookie.get('_online'),
    }
  },
  created(){

    if(this.on){
      this.useraccess = this.useraccess[0].toUpperCase() + this.useraccess.substr(1)
      this.online = true
    }
  },
};
</script>
