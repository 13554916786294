import LoginComponent from './../components/login/LoginComponent'
import UserCreateComponent from './../components/user/UserCreateComponent'
import UserPasswordRecoveryComponent from './../components/user/UserPasswordRecoveryComponent'
import HomeComponent from './../components/home/HomeComponent'
import IncomeComponent from './../components/income/IncomeComponent'
import ExpenseComponent from './../components/expense/ExpenseComponent'
import GroupComponent from './../components/group/GroupComponent'
import MatrixComponent from './../components/matrix/MatrixComponent'
import SubGroupComponent from './../components/subgroup/SubGroupComponent'
import InvestmentComponent from './../components/investment/InvestmentComponent'
import RealComponent from './../components/real/RealComponent'
import PagseguroComponent from './../components/pagseguro/PagseguroComponent'

import Guard from './../middleware/authentication'
import Subscriber from './../middleware/subscriber'

export default[
    //LOGIN
    {path: '/', component: LoginComponent},
    //USER
    {path: '/usuario/cadastro', component: UserCreateComponent},
    {path: '/usuario/recuperar-senha', component: UserPasswordRecoveryComponent},
    //HOME
    {path: '/home', component: HomeComponent, beforeEnter: Subscriber.auth},
    //INCOME
    {path: '/income', component: IncomeComponent, beforeEnter: Subscriber.auth},
    //EXPENSE
    {path: '/expense', component: ExpenseComponent, beforeEnter: Subscriber.auth},
    //INVESTIMENT
    {path: '/investment', component: InvestmentComponent, beforeEnter: Subscriber.auth},
    //REAL
    {path: '/real', component: RealComponent, beforeEnter: Subscriber.auth},
    //GROUP
    {path: '/grupo', component: GroupComponent, beforeEnter: Subscriber.auth},
    //MATRIX
    {path: '/matrix', component: MatrixComponent, beforeEnter: Subscriber.auth},
    //SUB-GROUP
    {path: '/sub-grupo', component: SubGroupComponent, beforeEnter: Subscriber.auth},
    //PAGSEGURO
    {path: '/pagseguro', component: PagseguroComponent, beforeEnter: Guard.auth},
]