<template>
    <li>
        <router-link :to="`${route}`"><i :class="icon"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{label}}</span></router-link>
    </li>
</template>

<script>
export default {
    props:[
        'icon',
        'label',
        'route',
    ]
}
</script>

<style scoped>
li{
    border-bottom: 1px solid #eee;
    padding: 0px 20px;
    width: 100%;
}
li a{
    text-decoration: none;
    display: block;
    padding: 20px 0;
    color: #72777a;
    text-transform: uppercase;
    font-size: 14px;
}
li a:hover{
    color: #4d4d4d;
}
li a svg{
    color: #6e8efb;
    font-size: 22px;
}
</style>