<template>
    <div class="login">

        <loading-component v-if="loadingParam"></loading-component>

        <div class="login-form">
            <div class="login-form-wrapper">

                <v-alert v-if="message.type" class="text-center" :type="message.type" v-text="message.message"></v-alert>

                <div class="login-title">
                    <h2>{{ title }}</h2>
                </div>
                <form @submit.prevent="createUser">
                    <div class="mb-3">
                        <label for="name" class="form-label">Nome</label>
                        <input type="text" class="form-control" id="name" v-model="userData.name">
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" v-model="userData.email">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Senha</label>
                        <input type="password" class="form-control" id="password" v-model="userData.password">
                    </div>

                    <button type="submit" class="btn">Salvar Alterações</button>
                </form>

                <div class="login-start">
                    <router-link to="/" class="login-create">Login</router-link>
                </div>
            </div>
        </div>

        <div class="banner-login">
            <img src="./../../assets/img/banner.png" alt="">
            <h2>Finanças Pessoais</h2>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

import LoadingComponent from './../snippets/LoadingComponent'

export default {
    components: {
        LoadingComponent,
    },
    props:[
        'url',
    ],
    data(){
        return{
            title: 'Cadastro',
            userData:{

                name: '',
                email: '',
                password: '',
            },
            message: false,
            loadingParam: false,
        }
    },
    methods:{

        createUser(){

            this.loadingParam = true

            axios.post(this.url + '/users/store', this.userData).then(response => {

                this.message = response.data

            }).finally(() => {

                this.loadingParam = false
            })
        },
    },
}
</script>

<style scoped>
.login{
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.login-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.login-form{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.login-form-wrapper{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}
.login-form-wrapper button{
    margin-top: 10px;
    width: 100%;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    border: none;
    color: #fff;
}
.login-form-wrapper button:hover{
    transform: scale(1.01);
    transition: .2s;
}
.login-start{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.login-start a{
    text-decoration: none;
    color: #777;
}
.banner-login{
    width: 50%;
    height: 100vh;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner-login img{
    width: 100%;
    max-width: 300px;
}

@media screen and (max-width: 991px){
    .login{
        padding: 0 20px;
    }
    .banner-login{
        display: none;
    }
    .login-form{
        width: 100%;
    }
}
</style>