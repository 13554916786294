<template>
    <v-footer class="footer" v-bind="fixed" :padless="true">
        <v-card flat tile width="100%" class="text-center" style="background: #eee;">
            <v-card-text>

                {{ new Date().getFullYear() }} — <strong>By Devalx Web Solutions</strong>

                <a href="" v-for="icon in icons" :key="icon" class="mx-4" icon target="_blank" title="Video explicativo">
                    <v-icon size="22px">{{ icon }}</v-icon>
                </a>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    data(){
        return{
            icons: [
                'mdi-youtube',
            ]
        }
    },
}
</script>

<style scoped>
    .footer a{
        text-decoration: none;
    }
</style>