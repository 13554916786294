<template>
    <div>
        <v-card>
            <v-card-title>

                <v-alert 
                text prominent 
                type="error" 
                icon="mdi-cloud-alert" 
                v-if="error.alerts"
                style="width: 100%;"
                >{{ error.alerts.message }}</v-alert>

                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line hide-details></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="name" class="elevation-1">

                <template v-slot:top>
                    
                    <v-toolbar flat>

                        <router-link to="/home" class="mb-2 btn-base btn-nav" v-if="useraccess == 'ACTIVE'">
                            <i class='fas fa-home'></i>
                            Dashboard
                        </router-link>

                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>

                        <!-- MODAL DE CRIAÇÃO -->
                        <v-dialog v-model="dialog" max-width="800px">

                            <!-- Botão de Abrir Modal -->
                            <template v-slot:activator="{ on, attrs }">
                                <button class="mb-2 btn-base" :class="useraccess == 'ACTIVE' || useraccess == 'SUSPENDED' ? 'btn-disabled' : 'btn-save'" v-bind="attrs" v-on="on" :disabled="useraccess == 'ACTIVE' || useraccess == 'SUSPENDED'">
                                    <i class="fab fa-amazon-pay"></i>
                                    {{ useraccess == 'ACTIVE' || useraccess == 'SUSPENDED' ? 'assinante' : 'pagar agora' }}
                                </button>
                            </template>
                            <!-- Botão de Abrir Modal (Fim) -->

                            <v-card>

                                <v-tabs v-model="tab" background-color="indigo" centered dark icons-and-text>

                                    <v-tabs-slider></v-tabs-slider>

                                    <v-tab href="#tab-1">
                                        Dados Pessoais
                                        <v-icon>mdi-account-edit</v-icon>
                                    </v-tab>

                                    <v-tab href="#tab-2">
                                        Dados do Cartão
                                        <v-icon>mdi-credit-card</v-icon>
                                    </v-tab>

                                </v-tabs>

                                <v-tabs-items v-model="tab" style="height: 450px; overflow: auto;">
                                    <v-tab-item key="1" value="tab-1">
                                        <!-- Formulário -->
                                        <v-card-text>
                                            <v-container>
                                                
                                                <v-row>
                                                    <!-- LINHA 01 -->
                                                    <v-col cols="12" md="7">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_name" 
                                                        label="Nome" 
                                                        :error-messages="error.errors ? error.errors.message.sender_name : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field-datetime 
                                                        v-model="editedItem.paymentMethod_creditCard_holder_birthDate" 
                                                        label="Data de Nascimento" 
                                                        :options="{inputMask: 'DD/MM/YYYY', empty: null,}"
                                                        :properties="{clearable: true, placeholder: 'DD/MM/YYYY', 'prepend-icon': 'mdi-calendar',}"
                                                        :error-messages="error.errors ? error.errors.message.birthDate : ''" 
                                                        ></v-text-field-datetime>
                                                        <small class="rule" v-if="error.errors" v-text="error.errors.message.paymentMethod_creditCard_holder_birthDate"></small>
                                                    </v-col>
                                                    <!-- LINHA 02 -->
                                                    <v-col cols="12" md="3">
                                                        <v-text-field-cpf 
                                                        v-model="editedItem.sender_documents_value" 
                                                        label="Cpf" 
                                                        :options="{outputMask: '###########'}"
                                                        :error-messages="error.errors ? error.errors.message.sender_documents_value : ''" 
                                                        ></v-text-field-cpf>
                                                        <small class="rule" v-if="error.errors" v-text="error.errors.message.sender_documents_value"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <v-text-field-cep 
                                                        v-model="editedItem.sender_address_postalCode" 
                                                        label="Cep" 
                                                        :options="{outputMask: '########'}"
                                                        :error-messages="error.errors ? error.errors.message.sender_address_postalCode : ''" 
                                                        ></v-text-field-cep>
                                                        <small class="rule" v-if="error.errors" v-text="error.errors.message.sender_address_postalCode"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_state" 
                                                        label="Estado" 
                                                        maxlenght="2"
                                                        :error-messages="error.errors ? error.errors.message.sender_address_state : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_city" 
                                                        label="Cidade" 
                                                        :error-messages="error.errors ? error.errors.message.sender_address_city : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <!-- LINHA 03 -->
                                                    <v-col cols="12" md="6">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_street" 
                                                        label="Rua" 
                                                        :error-messages="error.errors ? error.errors.message.sender_address_street : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_number" 
                                                        label="Número" 
                                                        :error-messages="error.errors ? error.errors.message.sender_address_number : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_district" 
                                                        label="Bairro" 
                                                        :error-messages="error.errors ? error.errors.message.sender_address_district : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <!-- LINHA 04 -->
                                                    <v-col cols="12" md="3">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_address_complement" 
                                                        label="Complemento" 
                                                        :error-messages="error.errors ? error.errors.message.sender_address_complement : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="1">
                                                        <v-text-field-integer 
                                                        v-model="editedItem.sender_phone_areaCode" 
                                                        label="DDD" 
                                                        :options="{inputMask: '##', outputMask: '##',}"
                                                        :error-messages="error.errors ? error.errors.message.sender_phone_areaCode : ''" 
                                                        ></v-text-field-integer>
                                                        <small class="rule" v-if="error.errors" v-text="error.errors.message.sender_phone_areaCode"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-text-field-simplemask 
                                                        v-model="editedItem.sender_phone_number" 
                                                        label="Cel" 
                                                        :properties="{clearable: true}"
                                                        :options="{inputMask: '#####-####', outputMask: '#########', empty: null, alphanumeric: true, lowerCase: false,}"
                                                        :error-messages="error.errors ? error.errors.message.sender_phone_number : ''" 
                                                        ></v-text-field-simplemask>
                                                        <small class="rule" v-if="error.errors" v-text="error.errors.message.sender_phone_number"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field 
                                                        v-model="editedItem.sender_email" 
                                                        label="Email" 
                                                        :error-messages="error.errors ? error.errors.message.sender_email : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-container>
                                        </v-card-text>
                                        <!-- Formulário (Fim) -->
                                    </v-tab-item>

                                    <v-tab-item key="2" id="tab-2">
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <!-- LINHA 01 -->
                                                    <v-col cols="12" md="8">
                                                        <v-text-field 
                                                        v-model="editedItem.paymentMethod_creditCard_holder_name" 
                                                        label="Nome do cartão" 
                                                        :error-messages="error.errors ? error.errors.message.paymentMethod_creditCard_holder_name : ''" 
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field-integer 
                                                        v-model="editedItem.paymentMethod_creditCard_cardNumber" 
                                                        label="Número do cartão" 
                                                        :options="{inputMask: '################', outputMask: '################',}"
                                                        :error-messages="errorCard ? errorCard.paymentMethod_creditCard_cardNumber : ''" 
                                                        ></v-text-field-integer>
                                                        <small class="rule" v-if="errorCard" v-text="errorCard.paymentMethod_creditCard_cardNumber"></small>
                                                    </v-col>
                                                    <!-- LINHA 02 -->
                                                    <v-col cols="12" md="4">
                                                        <v-text-field-integer 
                                                        v-model="editedItem.paymentMethod_creditCard_cvv" 
                                                        label="Código" 
                                                        :options="{inputMask: '###', outputMask: '###',}"
                                                        :error-messages="error.errors ? error.errors.message.paymentMethod_creditCard_cvv : ''" 
                                                        ></v-text-field-integer>
                                                        <small class="rule" v-if="errorCard" v-text="errorCard.paymentMethod_creditCard_cvv"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field-integer 
                                                        v-model="editedItem.paymentMethod_creditCard_expirationMonth" 
                                                        label="Mês" 
                                                        :options="{inputMask: '##', outputMask: '##',}"
                                                        :error-messages="error.errors ? error.errors.message.paymentMethod_creditCard_expirationMonth : ''" 
                                                        ></v-text-field-integer>
                                                        <small class="rule" v-if="errorCard" v-text="errorCard.paymentMethod_creditCard_expirationMonth"></small>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field-integer 
                                                        v-model="editedItem.paymentMethod_creditCard_expirationYear" 
                                                        label="Ano" 
                                                        :options="{inputMask: '####', outputMask: '####',}"
                                                        :error-messages="error.errors ? error.errors.message.paymentMethod_creditCard_expirationYear : ''" 
                                                        ></v-text-field-integer>
                                                        <small class="rule" v-if="errorCard" v-text="errorCard.paymentMethod_creditCard_expirationYear"></small>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-tab-item>
                                </v-tabs-items>

                                <v-card-actions>

                                    <v-spacer></v-spacer>

                                    <v-btn color="blue darken-1" text @click="close">
                                        Cancelar
                                    </v-btn>

                                    <v-btn color="blue darken-1" text @click="createPreApproval">
                                        Salvar
                                    </v-btn>

                                </v-card-actions>

                            </v-card>
                        </v-dialog>
                        <!-- MODAL DE CRIAÇÃO (FIM) -->

                        <v-dialog v-model="dialogPreApprovalStatus" max-width="700px">
                            <v-card>
                                <v-card-title class="text-h5">
                                    {{ alertUpdatePreApproval }}
                                </v-card-title>

                                <v-card-actions>
                                    <v-btn color="blue darken-1" text @click="closeStatusPreApproval">
                                        {{ preApprovalItem.status == 'INFO' ? 'Sair' : 'Não' }}
                                    </v-btn>

                                    <v-btn v-if="preApprovalItem.status != 'INFO'" color="blue darken-1" text @click="updateStatusPreApprovalConfirm">
                                        Sim
                                    </v-btn>
                                </v-card-actions>

                                <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="error.alerts">{{ error.alerts.message }}</v-alert>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <v-icon v-if="item.status == 'SUSPENDED'" small class="mr-2" @click="updatePreApproval(item)">
                        mdi-play-box
                    </v-icon>

                    <v-icon v-if="item.status == 'ACTIVE'" small class="mr-2" @click="updatePreApproval(item)">
                        mdi-pause
                    </v-icon>

                    <v-icon v-if="item.status == 'ACTIVE' || item.status == 'SUSPENDED'" small @click="updatePreApproval(item, true)">
                        mdi-delete
                    </v-icon>

                    <v-icon v-else style="background: #eee; border-radius: 100%; padding: 3px;" small class="mr-2" @click="updatePreApproval(item)">
                        mdi-information-variant
                    </v-icon>

                </template>

                <template v-slot:no-data>

                    <v-btn color="primary" @click="initialize">
                        Reset
                    </v-btn>

                </template>

            </v-data-table>
        </v-card>
    </div>
    
</template>

<script>

    import axios from 'axios'
    import PFCookie from 'js-cookie'
    import moment from 'moment'

    export default {
        data: () => ({
            dialog: false,
            dialogPreApprovalStatus: false,
            tab: null,
            search: '',
            items: [],
            headers: [
                {text: 'Nome', value: 'name'},
                {text: 'Usuário', value: 'client_name'},
                {text: 'Cpf', value: 'document'},
                {text: 'Status', value: 'status_text'},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            desserts: [],
            preApprovalIndex: 0,
            preApprovalItem: {
                code: '',
            },
            defaultPreApprovalItem: {
                code: '',
            },
            editedIndex: 0,
            editedItem: {
                user: {
                    id: PFCookie.get('_userid'),
                    name: PFCookie.get('_username'),
                },
                plan: process.env.VUE_APP_PLAN,
                reference: 'FP/' + PFCookie.get('_userid'),
                sender_name: '',
                sender_email: '',
                sender_hash: '',
                sender_phone_areaCode: '',
                sender_phone_number: '',
                sender_address_street: '',
                sender_address_number: '',
                sender_address_complement: '',
                sender_address_district: '',
                sender_address_city: '',
                sender_address_state: '',
                sender_address_country: 'BRA',
                sender_address_postalCode: '',
                sender_documents_type: 'CPF',
                sender_documents_value: '',
                paymentMethod_type: 'CREDITCARD',
                paymentMethod_creditCard_token: '',
                paymentMethod_creditCard_holder_name: '',
                paymentMethod_creditCard_holder_birthDate: '',
                paymentMethod_creditCard_cardNumber: '',
                paymentMethod_creditCard_cvv: '',
                paymentMethod_creditCard_expirationMonth: '',
                paymentMethod_creditCard_expirationYear: '',
            },
            defaultItem: {
                user: {
                    id: PFCookie.get('_userid'),
                    name: PFCookie.get('_username'),
                },
                plan: process.env.VUE_APP_PLAN,
                reference: 'FP/' + PFCookie.get('_userid'),
                sender_name: '',
                sender_email: '',
                sender_hash: '',
                sender_phone_areaCode: '',
                sender_phone_number: '',
                sender_address_street: '',
                sender_address_number: '',
                sender_address_complement: '',
                sender_address_district: '',
                sender_address_city: '',
                sender_address_state: '',
                sender_address_country: 'BRA',
                sender_address_postalCode: '',
                sender_documents_type: 'CPF',
                sender_documents_value: '',
                paymentMethod_type: 'CREDITCARD',
                paymentMethod_creditCard_token: '',
                paymentMethod_creditCard_holder_name: '',
                paymentMethod_creditCard_holder_birthDate: '',
                paymentMethod_creditCard_cardNumber: '',
                paymentMethod_creditCard_cvv: '',
                paymentMethod_creditCard_expirationMonth: '',
                paymentMethod_creditCard_expirationYear: '',
            },
            url: process.env.VUE_APP_URL,
            urlPay: process.env.VUE_APP_URL_PAY,
            useraccess: PFCookie.get('_useraccess'),
            preApprovalCode: PFCookie.get('_pre_approval_code'),
            message: false,
            moment: moment,
            loading: false,
            alertUpdatePreApproval: '',
            error: false,
            errorCard: false,
        }),
        computed:{
            formTitle(){
                
                return this.editedIndex <= 0 ? 'Assinatura' : ''
            },
        },
        watch:{
            dialogPreApprovalStatus(val){
                val || this.closeStatusPreApproval()
            }
        },
        created(){

            this.session()
            this.initialize()
        },

        methods: {
            initialize(){

                this.showLoading(true)

                axios.post(this.urlPay + '/pre-approvals/client', {code: this.preApprovalCode}).then(response => {

                    this.desserts = response.data

                }).finally(() => {

                    this.showLoading(false)
                })
            },
            session(){

                //Seta a Sessão
                axios.post(this.urlPay + '/pre-approvals/client/session', {}).then(response => {

                    let sessionId = response.data

                    window.PagSeguroDirectPayment.setSessionId(sessionId)
                })

                //Retorna o Hash
                window.PagSeguroDirectPayment.onSenderHashReady(function(response){

                    if(response.status == 'error') {

                        return false;
                    }

                    window.senderHash = response.senderHash; //Hash estará disponível nesta variável.
                })
            },
            createPreApproval(){

                this.showLoading(true)

                if(!this.editedItem.paymentMethod_creditCard_cardNumber)
                {
                    this.errorCard = {paymentMethod_creditCard_cardNumber: 'O campo [Número do cartão] é obrigatório'}
                    this.error = {alerts: {message: 'Existem campos obrigatórios a serem preenchidos'}}
                    this.showLoading(false)
                    this.dialog = false
                }
                else if(!this.editedItem.paymentMethod_creditCard_cvv)
                {
                    this.errorCard = {paymentMethod_creditCard_cvv: 'O campo [Código] é obrigatório'}
                    this.error = {alerts: {message: 'Existem campos obrigatórios a serem preenchidos'}}
                    this.showLoading(false)
                    this.dialog = false
                }
                else if(!this.editedItem.paymentMethod_creditCard_expirationMonth)
                {
                    this.errorCard = {paymentMethod_creditCard_expirationMonth: 'O campo [Mês] é obrigatório'}   
                    this.error = {alerts: {message: 'Existem campos obrigatórios a serem preenchidos'}}
                    this.showLoading(false)
                    this.dialog = false
                }
                else if(!this.editedItem.paymentMethod_creditCard_expirationYear)
                {
                    this.errorCard = {paymentMethod_creditCard_expirationYear: 'O campo [Mês] é obrigatório'}
                    this.error = {alerts: {message: 'Existem campos obrigatórios a serem preenchidos'}}
                    this.showLoading(false)
                    this.dialog = false   
                }
                else 
                {
                    this.configDate()

                    //Retorna o token do cartão de crédito
                    window.PagSeguroDirectPayment.createCardToken({

                        cardNumber: this.editedItem.paymentMethod_creditCard_cardNumber,
                        cvv: this.editedItem.paymentMethod_creditCard_cvv,
                        expirationMonth: this.editedItem.paymentMethod_creditCard_expirationMonth,
                        expirationYear: this.editedItem.paymentMethod_creditCard_expirationYear,
                        success: response => {

                            this.error = false
                            this.errorCard = false

                            this.editedItem.paymentMethod_creditCard_token = response.card.token
                            this.editedItem.sender_hash = window.senderHash

                            if(this.editedItem.paymentMethod_creditCard_holder_birthDate == '1969-12-31')
                            {
                                this.editedItem.paymentMethod_creditCard_holder_birthDate = ''
                            }

                            axios.post(this.urlPay + '/pre-approvals/client/create', {data: this.editedItem}).then(response => {

                                this.message = response.data

                                if(!this.message.alerts && !this.message.errors)
                                {
                                    PFCookie.set('_pre_approval_code', this.message.code)
                                    PFCookie.set('_useraccess', this.message.status)

                                    axios.post(this.url + '/users/pre-approval/update', this.message).then(() => {

                                        this.message = false
                                        this.error = false
                                        this.errorCard = false

                                        this.$nextTick(() => {
                                            this.editedItem = Object.assign({}, this.defaultItem)
                                            this.editedIndex = 0
                                        })

                                        this.dialog = false

                                        this.$router.go(0)
                                    })
                                }
                                else if(this.message.error)
                                {
                                    this.error = {alerts: {message: this.message.errors}}
                                    this.dialog = false
                                }
                                else 
                                {
                                    this.error = this.message
                                    this.dialog = false
                                }

                            }).finally(() => {

                                this.showLoading(false)
                            })
                        },
                        error: response => {

                            this.error = {alerts: {message: response.errors}}
                            this.showLoading(false)
                            this.dialog = false
                        }
                    })
                }
            },
            updatePreApproval(item, cancel = false){

                let message = 'Tem certeza que deseja STATUS a assinatura?'
                let status = '';

                if(!cancel && item.status == 'ACTIVE')
                {
                    status = 'SUSPENDED'
                    message = message.replace(/status/i, 'SUSPENDER')
                }
                else if(!cancel && item.status == 'SUSPENDED')
                {
                    status = 'ACTIVE'
                    message = message.replace(/status/i, 'ATIVAR')
                }
                else if(cancel)
                {
                    status = 'CANCELLED'
                    message = message.replace(/status/i, 'CANCELAR')
                }
                else 
                {
                    status = 'INFO'
                    message = 'Essa assinatura está CANCELADA?'
                }

                this.alertUpdatePreApproval = message
                this.dialogPreApprovalStatus = true

                this.preApprovalIndex = item.code
                this.preApprovalItem = {status: status}
            },
            closeStatusPreApproval(){

                this.dialogPreApprovalStatus = false
                this.message = {}

                this.$nextTick(() => {
                    this.preApprovalItem = Object.assign({}, this.defaultPreApprovalItem)
                    this.preApprovalIndex = 0
                })
            },
            updateStatusPreApprovalConfirm(){

                let status = this.preApprovalItem.status

                if(status == 'CANCELLED')
                {
                    this.showLoading(true)

                    axios.put(this.urlPay + '/pre-approvals/client/cancel', {code: this.preApprovalIndex}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                        this.message = response.data

                        if(this.message.type != 'error' && this.message.type != 'alert')
                        {
                            axios.post(this.url + '/users/pre-approval/update', this.message).then(() => {

                                this.initialize()
                                this.closeStatusPreApproval()
                            })
                        }

                    }).finally(() => {

                        this.showLoading(false)
                    })
                }
                else 
                {
                    this.showLoading(true)

                    axios.put(this.urlPay + '/pre-approvals/client/update', {code: this.preApprovalIndex, data: this.preApprovalItem}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                        this.message = response.data

                        if(this.message.type != 'error' && this.message.type != 'alert')
                        {
                            axios.post(this.url + '/users/pre-approval/update', this.message).then(() => {

                                this.initialize()
                                this.closeStatusPreApproval()
                            })
                        }

                    }).finally(() => {

                        this.showLoading(false)
                    })
                }
            },
            close(){

                this.dialog = false
                this.message = false
                this.error = false
                this.errorCard = false

                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = 0
                })
            },
            configDate(){

                if(this.editedItem.birthDate != ''){

                    let date = moment(new Date(this.editedItem.birthDate).toISOString()).format('yyyy-MM-DD')

                    this.editedItem.paymentMethod_creditCard_holder_birthDate = date
                }
            },
            showLoading(param)
            {
                this.$emit('loadingShow', param)
            },
        },
    }
</script>

<style scoped>

.btn-base{
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 5px 10px;
    margin-top: 20px;
    border-radius: 4px;
    text-decoration: none;
}
.btn-base:hover{
    transform: scale(1.02);
    transition: .2s;
}
.btn-save{
    background: linear-gradient(135deg, #02531091, #58ce09);
}
.btn-nav{
    background: linear-gradient(135deg, #6e8efb, #a777e3);
}
.btn-disabled{
    background: linear-gradient(135deg, #666, #999);
}
.rule{
    position: relative;
    bottom: 20px;
    font-size: 9pt;
    color: rgba(255, 0, 0, 0.705);
}

</style>