<template>
    <li class="sidebar-logout">
        <a href="" @click.prevent="logout"><i class="fas fa-sign-out-alt" style="color: #f36c8e;"></i><span>&nbsp;&nbsp;&nbsp;Sair</span></a>
    </li>
</template>

<script>

import PFCookie from 'js-cookie'
import axios from 'axios'

export default{
    data(){
        return{
            url: process.env.VUE_APP_URL,
            token: PFCookie.get('_pf_token'),
        }
    },
    methods:{
        logout(){

            this.showLoading(true)

            axios.post(this.url + '/logout', {}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(() => {

                PFCookie.remove('_email')
                PFCookie.remove('_password')
                PFCookie.remove('_pf_token')
                PFCookie.remove('_online')
                PFCookie.remove('_username')
                PFCookie.remove('_userid')
                PFCookie.remove('_useraccess')

            }).finally(() => {

                this.showLoading(false)
                
                this.$router.go(0)
            })
        },
        showLoading(param){

            this.$emit('loadingShow', param)
        }
    },
}
</script>

<style scoped>

li a{
    text-decoration: none;
    color: #72777a;
}

@media screen and (max-width: 1370px){
    li{
        border-bottom: 1px solid #eee;
        padding: 0px 20px;
        width: 100%;
    }
    li a{
        text-decoration: none;
        display: block;
        padding: 20px 0;
        color: #72777a;
        text-transform: uppercase;
        font-size: 14px;
    }
    li a:hover{
        color: #4d4d4d;
    }
    li a svg{
        font-size: 22px;
    }
}
</style>