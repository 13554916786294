<template>
   <div>
     <apexchart height="250px" :options="dataSetComp.chartOptions" :series="dataSetComp.series"></apexchart>
   </div>
</template>

<script>

export default {
    props:[
        'dataSet',
    ],
    computed:{

        dataSetComp(){

            return {

                chartOptions: {
                    colors: ['#4169E1'],
                    chart: {
                        type: 'bar'
                    },
                    xaxis: {
                        categories: ['Receitas', 'Despesas', 'Investimentos']
                    }
                },
                series: [{
                    data: [this.dataSet.income.total, this.dataSet.expense.total, this.dataSet.investment.total,]
                }]
            }
        },
    },
}
</script>