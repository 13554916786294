
export default{
    show(){

        return [
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-home',
                label: 'Dashboard',
                route: '/home',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-university',
                label: 'Matriz',
                route: '/matrix',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-layer-group',
                label: 'Grupos',
                route: '/grupo',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-object-group',
                label: 'Subgrupos',
                route: '/sub-grupo',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-plus-circle',
                label: 'Receitas',
                route: '/income',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-minus-circle',
                label: 'Despesas',
                route: '/expense',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-chart-pie',
                label: 'Investimentos',
                route: '/investment',
            },
            {
                auth: ['ACTIVE'],
                icon: 'fas fa-file-csv',
                label: 'Resumo',
                route: '/real',
            },
            {
                auth: ['ACTIVE', 'SUSPENDED', 'CANCELLED'],
                icon: 'fab fa-amazon-pay',
                label: 'Pagamento',
                route: '/pagseguro',
            },
        ]
    }
}