<template>
    <div class="header">
        <div>
            <img src="./../../assets/img/banner.png" alt="">
            <span>{{label}}</span>
        </div>

        <ul>
            <logout-component class="sidebar-logout" @loadingShow="loadingShow"></logout-component>
        </ul>

        <div class="btn-bars" @click.prevent="showMenu">
            <i class="fas fa-bars"></i>
        </div>
    </div>
</template>

<script>

import PFCookie from 'js-cookie'
import axios from 'axios'
import LogoutComponent from './../snippets/LogoutComponent.vue'

export default {
    components:{
        LogoutComponent,
    },
    props:[
        'label',
        'url',
        'token',
    ],
    data(){
        return{
            menuShow: false,
        }
    },
    methods:{
        logout(){

            axios.post(this.url + '/logout', {}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(() => {

                PFCookie.remove('_email')
                PFCookie.remove('_password')
                PFCookie.remove('_pf_token')
                PFCookie.remove('_online')
                PFCookie.remove('_username')
                PFCookie.remove('_userid')
                PFCookie.remove('_useraccess')

                this.$router.go(0)

            }, error => {

                console.log(error)
            })
        },
        showMenu(){
            if(this.menuShow)
            {
                this.menuShow = false
            }
            else 
            {
                this.menuShow = true
            }

            this.$emit('showMenu', this.menuShow)
        },
        loadingShow(param){

            this.$emit('loadingShow', param)
        }
    },
}
</script>

<style scoped>
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 30px;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, .0625);
}
.header img{
    width: 60px;
    margin-right: 10px;
}
.header span{
    font-size: 20px;
    color: #72777a;
}
.btn-bars{
    display: none;
}
.header ul{
    list-style-type: none;
}

@media screen and (max-width: 1370px){
    .header .sidebar-logout{
        display: none;
    }
    .btn-bars{
        display: block;
        padding: 10px;
    }

    .btn-bars:hover
    {
        cursor: pointer;
    }
}

@media screen and (max-width: 310px){
    .header{
        justify-content: center;
    }
    .header div span{
        display: none;
    }
}
</style>