import PFCookie from 'js-cookie'

export default{

    auth(to, from, next){

        const useraccess = PFCookie.get('_useraccess')

        if(useraccess == 'ACTIVE')
        {
            next()
        }
        else 
        {
            next('/pagseguro')
        }
    }
}