<template>
   <div>
     <apexchart height="250" :options="dataSetComp.chartOptions" :series="dataSetComp.series"></apexchart>
   </div>
</template>

<script>

export default {
    props:[
        'dataSetNames',
        'dataSetTotals',
    ],
    computed:{

        dataSetComp(){

            return {

                chartOptions: {
                    colors: ['#DAA520'],
                    chart: {
                        type: 'bar'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    xaxis: {
                        categories: this.dataSetNames
                    }
                },
                series: [{
                    data: this.dataSetTotals
                }]
            }
        },
    },
}
</script>