<template>
    <div>
        <v-card>
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line hide-details></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="desserts" :search="search" sort-by="sub_group_name" class="elevation-1">

                <template v-slot:top>
                    
                    <v-toolbar flat>

                        <router-link to="/home" class="mb-2 btn-base btn-nav">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </router-link>

                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>

                        <!-- MODAL DE CRIAÇÃO -->
                        <v-dialog v-model="dialog" max-width="700px">

                            <!-- Botão de Abrir Modal -->
                            <template v-slot:activator="{ on, attrs }">
                                <button class="mb-2 btn-base btn-save" v-bind="attrs" v-on="on">
                                    <i class="fas fa-plus"></i>
                                    mais receita
                                </button>
                            </template>
                            <!-- Botão de Abrir Modal (Fim) -->

                            <v-card>

                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <!-- Formulário -->
                                <v-card-text>
                                    <v-container>

                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete @change="getSubGroups" ref="group" v-model="editedItem.group_id" label="Grupo" placeholder="Selecione..." :items="groups" item-value="id" item-text="name"></v-autocomplete>
                                                <small class="rule" v-if="message.type == 'error'" v-text="message.message.group_id"></small>
                                            </v-col>
                                            
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete ref="subgroup" v-model="editedItem.sub_group_id" label="Origem" placeholder="Selecione..." :items="subgroups" item-value="id" item-text="name"></v-autocomplete>
                                                <small class="rule" v-if="message.type == 'error'" v-text="message.message.sub_group_id"></small>
                                            </v-col>

                                            <v-col cols="12" sm="6">
                                                <v-text-field-money v-model="editedItem.total" label="Total" :properties="{prefix: 'R$'}"></v-text-field-money>
                                                <small class="rule" v-if="message.type == 'error'" v-text="message.message.total"></small>
                                            </v-col>

                                            <v-col cols="12" sm="6">
                                                <v-autocomplete ref="destiny" v-model="editedItem.destiny_id" label="Destino" placeholder="Selecione..." :items="destinies" item-value="id" item-text="name"></v-autocomplete>
                                                <small class="rule" v-if="message.type == 'error'" v-text="message.message.destiny_id"></small>
                                            </v-col>

                                            <v-col cols="12" sm="6">
                                                <v-text-field-datetime v-model="editedItem.date" label="Data" :properties="{clearable: true, placeholder: 'DD/MM/AAAA', 'prepend-icon': 'mdi-calendar',}" :options="{inputMask: 'DD/MM/YYYY', empty: null,}"></v-text-field-datetime>
                                                <small class="rule" v-if="message.type == 'error'" v-text="message.message.date"></small>
                                            </v-col>
                                        </v-row>

                                    </v-container>
                                </v-card-text>
                                <!-- Formulário (Fim) -->

                                <v-card-actions>

                                    <v-spacer></v-spacer>

                                    <v-btn color="blue darken-1" text @click="close">
                                        Cancelar
                                    </v-btn>

                                    <v-btn color="blue darken-1" text @click="save">
                                        Salvar
                                    </v-btn>

                                </v-card-actions>

                            </v-card>
                        </v-dialog>
                        <!-- MODAL DE CRIAÇÃO (FIM) -->

                        <v-dialog v-model="dialogDelete" max-width="700px">
                            <v-card>
                                <v-card-title class="text-h5">
                                    Tem certeza de que deseja excluir este item?
                                </v-card-title>

                                <v-card-actions>

                                    <v-spacer></v-spacer>
                                    
                                    <v-btn color="blue darken-1" text @click="closeDelete">
                                        Não
                                    </v-btn>

                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                                        Sim
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                    
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">

                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>

                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>

                </template>

                <template v-slot:no-data>

                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>

                </template>

            </v-data-table>
        </v-card>
    </div>
    
</template>

<script>

    import axios from 'axios'
    import PFCookie from 'js-cookie'
    import moment from 'moment'

    export default {
        data: () => ({
            dialog: false,
            dialogDelete: false,
            search: '',
            items: [],
            headers: [
                {text: 'Origem', value: 'sub_group_name'},
                {text: 'Valor', value: 'total_name'},
                {text: 'Destino', value: 'destiny_name'},
                {text: 'Mês', value: 'month_name'},
                {text: 'Ano', value: 'year'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            desserts: [],
            editedIndex: 0,
            editedItem:{
                user_id: PFCookie.get('_userid'),
                group_id: '',
                sub_group_id: '',
                destiny_id: '',
                total: '',
                date: '',
            },
            defaultItem:{
                user_id: PFCookie.get('_userid'),
                group_id: '',
                sub_group_id: '',
                destiny_id: '',
                total: '',
                date: '',
            },
            url: process.env.VUE_APP_URL,
            token: PFCookie.get('_pf_token'),
            message: {},
            groups: [],
            subgroups: [],
            destinies: [],
            moment: moment,
            user_id: PFCookie.get('_userid'),
            loading: false
        }),
        computed:{
            formTitle(){
                
                return this.editedIndex <= 0 ? 'Nova Receita' : 'Editar Receita'
            },
        },
        watch:{
            dialog(val){
                val || this.close()
            },
            dialogDelete(val){
                val || this.closeDelete()
            },
        },
        created(){

            this.initialize()
        },

        methods: {
            initialize(){

                this.showLoading(true)

                axios.post(this.url + '/incomes', {user_id: this.user_id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.desserts = response.data

                }).finally(() => {

                    this.showLoading(false)
                })
                
                axios.post(this.url + '/incomes/groups', {user_id: this.user_id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.groups = response.data
                })
                
                axios.post(this.url + '/incomes/matrixes', {user_id: this.user_id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.destinies = response.data
                })
            },
            getSubGroups(id){
                
                this.showLoading(true)

                axios.post(this.url + '/incomes/subgroups', {user_id: this.user_id, id: id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.subgroups = response.data
                    
                }).finally(() => {

                    this.showLoading(false)
                })
            },
            editItem(item){
                this.editedIndex = item.id
                this.editedItem = Object.assign({}, item)
                this.dialog = true

                axios.post(this.url + '/incomes/subgroups', {user_id: this.user_id, id: this.editedItem.group_id}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.subgroups = response.data
                })
            },
            deleteItem(item){
                this.editedIndex = item.id
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            deleteItemConfirm(){

                axios.post(this.url + '/incomes/destroy', {id: this.editedIndex}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                    this.message = response.data

                    if(this.message.type != 'error' && this.message.type != 'danger')
                    {
                        this.initialize()

                        this.closeDelete()
                    }
                })
            },
            close(){
                this.message = {}
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = 0
                })
            },
            closeDelete(){
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = 0
                })
            },
            save(){
                if(this.editedIndex > 0){
                    
                    this.editedItem.user_id = this.user_id
                    this.configDate()

                    axios.put(this.url + '/incomes/update', {id: this.editedIndex, data: this.editedItem}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                        this.message = response.data

                        if(this.message.type != 'error' && this.message.type != 'danger')
                        {
                            this.initialize()

                            this.close()
                        }
                    })
                }
                else{

                    this.configDate()

                    axios.post(this.url + '/incomes/store', {data: this.editedItem}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                        this.message = response.data

                        if(this.message.type != 'error' && this.message.type != 'danger')
                        {
                            this.initialize()

                            this.close()
                        }
                    })
                }
            },
            configDate(){

                if(this.editedItem.date != ''){

                    let date = moment(new Date(this.editedItem.date).toISOString()).format('yyyy-MM-DD')

                    this.editedItem.month_id = date.split('-')[1]
                    this.editedItem.year = date.split('-')[0]

                }
            },
            showLoading(param)
            {
                this.$emit('loadingShow', param)
            },
        },
    }
</script>

<style scoped>

.btn-base{
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 5px 10px;
    margin-top: 20px;
    border-radius: 4px;
    text-decoration: none;
}
.btn-base:hover{
    transform: scale(1.02);
    transition: .2s;
}
.btn-save{
    background: linear-gradient(135deg, #02531091, #58ce09);
}
.btn-nav{
    background: linear-gradient(135deg, #6e8efb, #a777e3);
}
.rule{
    color: red;
}

</style>