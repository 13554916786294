import { render, staticRenderFns } from "./UserPasswordRecoveryComponent.vue?vue&type=template&id=dc7e280e&scoped=true&"
import script from "./UserPasswordRecoveryComponent.vue?vue&type=script&lang=js&"
export * from "./UserPasswordRecoveryComponent.vue?vue&type=script&lang=js&"
import style0 from "./UserPasswordRecoveryComponent.vue?vue&type=style&index=0&id=dc7e280e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc7e280e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
