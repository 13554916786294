<template>

  <v-card>

    <v-card-title>
      <v-col cols="12">
        <h4>
          RECEITAS E DESPESAS {{ year }} 
        </h4>
        <v-autocomplete @change="getYear" ref="year" label="Ano" placeholder="Selecione..." :items="years"></v-autocomplete>
      </v-col>
    </v-card-title>

     <v-data-table :headers="headers" :items="desserts" :items-per-page="15" class="elevation-1" ></v-data-table>

  </v-card>

</template>

<script>

  import axios from 'axios'
  import PFCookie from 'js-cookie'
  import moment from 'moment'

  export default {
    data () {
      return {
        url: process.env.VUE_APP_URL,
        token: PFCookie.get('_pf_token'),
        moment: moment,
        years: [],
        user: PFCookie.get('_userid'),
        year: '',
        headers: [
          {text: 'Resumo', align: 'start', sortable: false, value: 'name',},
          { text: 'Jan', value: 'jan'},
          { text: 'Fev', value: 'fev'},
          { text: 'Mar', value: 'mar'},
          { text: 'Abr', value: 'abr'},
          { text: 'Mai', value: 'mai' },
          { text: 'Jun', value: 'jun' },
          { text: 'Jul', value: 'jul' },
          { text: 'Ago', value: 'ago' },
          { text: 'Set', value: 'set' },
          { text: 'Out', value: 'out' },
          { text: 'Nov', value: 'nov' },
          { text: 'Dez', value: 'dez' },
          { text: 'Total', value: 'total' },
        ],
        desserts: [],
      }
    },
    created(){

      let currentTime = new Date();
      this.year = currentTime.getFullYear()

      this.listYears()
      this.list()
    },
    methods:{

      list(){

        this.showLoading(true)

        axios.post(this.url + '/reals', {year: this.year, user: this.user}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

          this.desserts = response.data

        }).finally(() => {

          this.showLoading(false) 
        })
      },
      listYears(){

        axios.post(this.url + '/reals/years', {user: this.user}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

          this.years = Object.values(response.data.years)

        })
      },
      getYear(data){

        this.year = data
        this.list()
      },
      showLoading(param){

          this.$emit('loadingShow', param)
      }
    },
  }
</script>