<template>
    <div class="sidebar">
        <div class="sidebar-title">
            <img src="./../../assets/img/user.png" alt="">
            <h2>{{ username.split(' ')[0] }} <b>{{ username.split(' ')[1] }}</b></h2>
        </div>
        <ul>
            <!-- Menus Principais do Sistema -->
            <div v-for="(menu, index) in menus" :key="index">
                <menu-component
                    v-if="menu.auth.includes(useraccess)"
                    :icon="menu.icon"
                    :label="menu.label"
                    :route="menu.route"
                />
            </div>
            <!-- Fim dos menus -->

            <logout-component class="sidebar-logout" @loadingShow="loadingShow"></logout-component>
        </ul>
    </div>
</template>

<script>

import PFCookie from 'js-cookie'
import Menus from './../../menu.js'
import MenuComponent from './../snippets/MenuComponent.vue'
import LogoutComponent from './../snippets/LogoutComponent.vue'

export default {
    props:[
        'url',
        'token',
    ],
    components:{
        MenuComponent,
        LogoutComponent,
    },
    data(){
        return{
            menus: Menus.show(),
            useraccess: PFCookie.get('_useraccess'),
            username: PFCookie.get('_username'),
        }
    },
    methods:{
        
        loadingShow(param){

            this.$emit('loadingShow', param)
        }
    },
}
</script>

<style scoped>
.sidebar{
    display: block;
    height: 100vh;
    width: 300px;
    background-color: #fff;
    overflow: scroll;
}
.sidebar::-webkit-scrollbar {
    display: none;
}
.sidebar .sidebar-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, .0625);
    background-color: #72777a;
}
.sidebar .sidebar-title img{
    width: 50px;
    border-radius: 3px;
    margin-top: 10px;
    border-radius: 50%;
    border: 3px solid #eee;
}
.sidebar .sidebar-title h2{
    font-size: 13px;
    margin-top: 8px;
    color: #fff;
}
.sidebar .sidebar-title .sidebar-bars{
    display: none;
}
.sidebar ul li.sidebar-logout{
    display: none;
}
.sidebar ul{
    padding: 0;
    list-style-type: none;
}

@media screen and (max-width: 1370px){
    .sidebar ul li.sidebar-logout{
        display: block;
    }
}
</style>