import PFCookie from 'js-cookie'

export default{

    auth(to, from, next){

        const token = PFCookie.get('_pf_token')
        // const useraccess = PFCookie.get('_useraccess')

        if(!token)
        {
            next('/')
        }

        next()
    }
}