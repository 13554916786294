<template>
    <div class="flex-dashboard">

        <loading-component v-if="loadingParam"></loading-component>

        <div class="menu">
            <sidebar-component @loadingShow="loadingShow"></sidebar-component>
        </div>

        <main>

            <header-component :label="title" @showMenu="showMenu" @loadingShow="loadingShow"></header-component>

            <div class="main-content">
                <div>
                    <sub-group-data-table-component @loadingShow="loadingShow"></sub-group-data-table-component>
                </div>
            </div>

            <footer-component></footer-component>
        </main>
    </div>
</template>

<script>

import SidebarComponent from './../snippets/SidebarComponent'
import HeaderComponent from './../snippets/HeaderComponent'
import SubGroupDataTableComponent from './SubGroupDataTableComponent'
import LoadingComponent from './../snippets/LoadingComponent'
import FooterComponent from './../snippets/FooterComponent'

export default {
    components:{
        SidebarComponent,
        HeaderComponent,
        SubGroupDataTableComponent,
        LoadingComponent,
        FooterComponent,
    },
    data(){
        return{
            title: 'SUB-GRUPOS',
            loadingParam: false,
        }
    },
    created(){
        //
    },
    methods:{
        showMenu(param){
            if(param)
            {
                document.querySelector('.menu').classList.add('addMenu')
            }
            else 
            {
                document.querySelector('.menu').classList.remove('addMenu')
            }
        },
        loadingShow(param){

            this.loadingParam = param
        }
    },
}
</script>

<style scoped>
.flex-dashboard{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}
main{
    display: block;
    height: 100vh;
    width: calc(100% - 300px);
}
main .main-content{
    padding: 30px;
    height: calc(100vh - 135px);
    background: #f7f7f7;
    overflow: auto;
}
main .main-content::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
main .main-content::-webkit-scrollbar{
    width: 12px;
    background-color: #f5f5f5;
}
main .main-content::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #6e8efb;
}
.menu{
    border-right: 1px solid rgba(0, 0, 0, .0625)
}

@media screen and (max-width: 1370px){
    main{
        width: 100%;
    }
    .menu{
        z-index: 99;
        position: absolute;
        left: -300px;
        transition: all 0.3s ease;
    }
    .addMenu{
        
        left: 0;
    }
}
</style>