<template>
    <div class="flex-dashboard">

        <loading-component v-if="loadingParam"></loading-component>

        <div class="menu">
            <sidebar-component :url="url" :token="token" @loadingShow="loadingShow"></sidebar-component>
        </div>

        <main>
            
            <header-component :label="title" :url="url" :token="token" @showMenu="showMenu" @loadingShow="loadingShow"></header-component>

            <div class="main-content">

                <v-card>
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h5>
                                        RECEITAS E DESPESAS {{ month }}/{{ year }} 
                                    </h5>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="year" @change="getYear" ref="year" label="Ano" placeholder="Selecione..." :items="years"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="month" @change="getMonth" ref="month" label="Mês" placeholder="Selecione..." :items="months" item-value="id" item-text="name"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>

                    <v-container class="container-body">
                        <v-row>
                            <v-col class="box-items" cols="12" sm="6" xl="4">
                                <div class="dashboard-box">
                                    <div class="box-icon box-receitas">
                                        <i class="fas fa-plus-circle"></i>
                                    </div>
                                    <div class="value">{{ dashResume.income.total }}</div>
                                    <div class="type">Total Receitas</div>
                                </div>
                            </v-col>
                            
                            <v-col class="box-items" cols="12" sm="6" xl="4">
                                <div class="dashboard-box">
                                    <div class="box-icon box-despesas">
                                        <i class="fas fa-minus-circle"></i>
                                    </div>
                                    <div class="value">{{ dashResume.expense.total }}</div>
                                    <div class="type">Total Despesas</div>
                                </div>
                            </v-col>
                            
                            <v-col class="box-items" cols="12" sm="6" xl="4">
                                <div class="dashboard-box">
                                    <div class="box-icon box-investido">
                                        <i class="fas fa-chart-pie"></i>
                                    </div>
                                    <div class="value">{{ dashResume.investment.total }}</div>
                                    <div class="type">Valor Investido</div>
                                </div>
                            </v-col>
                            
                            <v-col class="box-items" cols="12" sm="6" xl="4">
                                <div class="dashboard-box">
                                    <div class="box-icon box-saldo">
                                        <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <div class="value">{{ dashResume.balance.total }}</div>
                                    <div class="type">Saldo</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <span>&nbsp;</span>

                <v-card>
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h5>DADOS ESTATÍSTICOS {{ year }}</h5>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>

                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <label class="chart-title result">RESULTADO {{ month }}/{{ year }}</label>
                                <chart-result-component :dataSet="dashResume" style="border: 1px solid #4169E1"></chart-result-component>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <label class="chart-title expense">DIVISÃO DE DESPESAS {{ month }}/{{ year }}</label>
                                <chart-expense-component :dataSetNames="dashExpenseNames" :dataSetTotals="dashExpenseTotals" style="border: 1px solid #FF0000"></chart-expense-component>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col cols="12" lg="6">
                                <label class="chart-title income">DIVISÃO DE RECEITAS {{ month }}/{{ year }}</label>
                                <chart-income-component :dataSetNames="dashIncomeNames" :dataSetTotals="dashIncomeTotals" style="border: 1px solid #20B2AA"></chart-income-component>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <label class="chart-title investment">DIVISÃO DE INVESTIMENTOS {{ month }}/{{ year }}</label>
                                <chart-investment-component :dataSetNames="dashInvestmentNames" :dataSetTotals="dashInvestmentTotals" style="border: 1px solid #DAA520"></chart-investment-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <span>&nbsp;</span>

                <v-card>
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h5>
                                        HISTÓRICO {{ year }} 
                                    </h5>
                                    <small v-if="movie" style="color: #888;">{{ totalHistory }}</small>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="movie" @change="listCategories" ref="year" label="Movimetação" placeholder="Selecione..." :items="movies" item-value="id" item-text="name"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="category" @change="listHistories" ref="month" label="Categoria" placeholder="Selecione..." :items="categories" item-value="id" item-text="name"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                </v-card>

                <span>&nbsp;</span>

                <v-card v-if="movie">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <label class="chart-title result">
                                    {{ movie ? movies.find(movie => movie.id == this.movie).name : '' }}{{ category ? '/' + categories.find(category => category.id == this.category).name : '' }}
                                </label>
                                <chart-history-component :dataSetNames="dashHistoryNames" :dataSetTotals="dashHistoryTotals" style="border: 1px solid #4169E1"></chart-history-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </div>

            <footer-component></footer-component>
        </main>
    </div>
</template>

<script>

import axios from 'axios'
import PFCookie from 'js-cookie'

import LoadingComponent from './../snippets/LoadingComponent'
import SidebarComponent from './../snippets/SidebarComponent'
import HeaderComponent from './../snippets/HeaderComponent'
import ChartResultComponent from './../snippets/ChartResultComponent'
import ChartExpenseComponent from './../snippets/ChartExpenseComponent'
import ChartIncomeComponent from './../snippets/ChartIncomeComponent'
import ChartInvestmentComponent from './../snippets/ChartInvestmentComponent'
import ChartHistoryComponent from './../snippets/ChartHistoryComponent'
import FooterComponent from './../snippets/FooterComponent.vue'

export default {
    components:{
        SidebarComponent,
        HeaderComponent,
        ChartResultComponent,
        ChartExpenseComponent,
        ChartIncomeComponent,
        ChartInvestmentComponent,
        ChartHistoryComponent,
        LoadingComponent,
        FooterComponent,
    },
    props:[
        'url',
        'token',
    ],
    data(){
        return{
            title: 'DASHBOARD',
            show: false,
            dashResume: {
                income: 0,
                expense: 0,
                investment: 0,
                balance: 0,
            },
            dashIncomeNames: [],
            dashIncomeTotals: [],
            dashExpenseNames: [],
            dashExpenseTotals: [],
            dashInvestmentNames: [],
            dashInvestmentTotals: [],
            dashHistoryNames: [],
            dashHistoryTotals: [],
            user: PFCookie.get('_userid'),
            year: '',
            month: '',
            years: [],
            months: [],
            movies: [],
            categories: [],
            movie: 0,
            category: 0,
            totalHistory: 0,
            loadingParam: false,
        }
    },
    created(){

        let currentTime = new Date();
        
        this.year = currentTime.getFullYear()

        this.listYears()
        this.listDashResume()
        this.listDashDivision()
        this.listMovies()
    },
    methods:{
        logout(){

            this.$router.push('/')
        },
        showMenu(param){
            
            if(param)
            {
                document.querySelector('.menu').classList.add('addMenu')
            }
            else 
            {
                document.querySelector('.menu').classList.remove('addMenu')
            }
        },
        listDashResume(){

            axios.post(this.url + '/dashboard/resume', {user: this.user, year: this.year, month: this.month}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.dashResume = response.data

            })
        },
        listDashDivision(){

            this.loadingParam = true

            axios.post(this.url + '/dashboard/division', {user: this.user, year: this.year, month: this.month}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.dashIncomeNames = response.data.incomes.names 
                this.dashIncomeTotals = response.data.incomes.totals
                
                this.dashExpenseNames = response.data.expenses.names 
                this.dashExpenseTotals = response.data.expenses.totals
                
                this.dashInvestmentNames = response.data.investments.names 
                this.dashInvestmentTotals = response.data.investments.totals
            }).finally(() => {

                this.loadingParam = false
            })
        },
        listDashHistory(){

            this.loadingParam = true

            axios.post(this.url + '/dashboard/histories', {user: this.user, year: this.year, group: this.movie, subgroup: this.category}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.dashHistoryNames = response.data.names 
                this.dashHistoryTotals = response.data.totals
                this.totalHistory = response.data.totalHistory
            }).finally(() => {

                this.loadingParam = false
            })
        },
        listYears(){

            axios.post(this.url + '/reals/years', {user: this.user}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.years = Object.values(response.data.years)
                this.months = Object.values(response.data.months)
            })
        },
        listMovies(){

            axios.post(this.url + '/dashboard/movies', {user: this.user, year: this.year}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.movies = Object.values(response.data)
            })
        },
        listCategories(data){

            this.category = 0

            this.listDashHistory()

            axios.post(this.url + '/dashboard/categories', {user: this.user, group: data, year: this.year}, {headers: {'Authorization': this.token, 'Accept': 'application/json'}}).then(response => {

                this.categories = Object.values(response.data)
            })
        },
        listHistories(){

            this.listDashHistory()
        },
        getYear(data){

            this.year = data
            this.listDashResume()
            this.listDashDivision()
            this.listMovies()
            this.listDashHistory()
            this.listCategories(this.movie)
        },
        getMonth(data){

            this.month = data 
            this.listDashResume()
            this.listDashDivision()
        },
        loadingShow(param){

            this.loadingParam = param
        }
    },
}
</script>

<style>
.box-items{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.flex-dashboard{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}
main{
    display: block;
    height: 100vh;
    width: calc(100% - 300px);
}
main .main-content{
    padding: 30px;
    height: calc(100vh - 135px);
    background: #f7f7f7;
    overflow: auto;
}
main .main-content::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
main .main-content::-webkit-scrollbar{
    width: 12px;
    background-color: #f5f5f5;
}
main .main-content::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #6e8efb;
}
main .main-content .panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ccc;
    margin: 10px 0;
}
main .main-content .main-content-title{
        border-bottom: 1px solid #999;
}
main .main-content .main-content-title span{
    color: #72777a;
    font-size: 18px; 
    padding-bottom: 10px;
}
.dashboard-content{
    padding: 10px 0;
}
.dashboard-flex-parent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 25px;
}
.dashboard-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: #fbfbfb;
    border: 1px solid #eee;
    border-radius: 5px;
}
.dashboard-box:hover{
    transition: 0.5s;
    box-shadow: 0 0 1rem rgba(156, 156, 156, 0.4);
    transform: scale(1.03);
}
.box-icon{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box-icon svg{
    font-size: 18px;
}
.box-receitas{
    background-color: rgba(110, 142, 251, 0.1);
    color: #6283f5;
}
.box-despesas{
    background-color: rgba(229, 115, 115, 0.1);
    color: #ef5350;
}
.box-investido{
    background-color: rgba(220, 231, 117, 0.4);
    color: #afb42b;
}
.box-saldo{
    background-color: rgba(255, 138, 101, 0.1);
    color: #ff5722;
}
.dashboard-box .value{
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
}
.dashboard-box .type{
    text-transform: uppercase;
    font-size: 12px;
}
.menu{
    border-right: 1px solid rgba(0, 0, 0, .0625);
}
.chart-title{

    padding: 10px 0; 
    width: 100%; 
    text-align: center; 
    color: #fff; 
    border-radius: 5px 5px 0 0;
}
.chart-title.result
{
    background: #4169E1; 
}
.chart-title.expense
{
    background: #FF0000; 
}
.chart-title.income
{
    background: #20B2AA; 
}
.chart-title.investment
{
    background: #DAA520; 
}

@media screen and (max-width: 1380px){
    .dashboard-box{
        max-width: 340px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1370px){
    main{
        width: 100%;
    }
    .menu{
        z-index: 99;
        position: absolute;
        left: -300px;
        transition: all 0.3s ease;
    }
    .addMenu{
        
        left: 0;
    }
}
</style>