<template>
    <div class="login">

        <loading-component v-if="loadingParam"></loading-component>

        <div class="login-form">

            <div class="login-form-wrapper">

                <v-alert v-if="message.type" class="text-center" :type="message.type" v-text="message.message"></v-alert>

                <div class="login-title">
                    <h2>Login</h2>
                </div>
                <form @submit.prevent="loginPF">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" v-model="login.email">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Senha</label>
                        <input type="password" class="form-control" id="password" v-model="login.password">
                    </div>

                    <button type="submit" class="btn">Login</button>
                </form>

                <div class="login-start">
                    <router-link to="/usuario/cadastro" class="login-create">Inscreva-se Aqui</router-link>
                    <router-link to="/usuario/recuperar-senha" class="login-create">Recuperar Senha</router-link>
                </div>
            </div>
        </div>

        <div class="banner-login">
            <img src="./../../assets/img/banner.png" alt="">
            <h2>Finanças Pessoais</h2>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import PFCookie from 'js-cookie'
import LoadingComponent from './../snippets/LoadingComponent'

export default {
    props:[
        'url',
        'token',
    ],
    components: {

        LoadingComponent,
    },
    data(){
        return{
            title: 'Login',
            login:{
                email: '',
                password: '',
            },
            message: false,
            loadingParam: false,
        }
    },
    created(){
        if(this.token){
            this.$router.push('/home')
        }
    },
    methods:{
        loginPF(){

            this.loadingShow(true)
            
            axios.post(this.url, this.login).then(response => {

                this.message = response.data

                if(this.message.type == 'success')
                {
                    let token = response.data.auth.original.token_type + ' ' + response.data.auth.original.access_token
                    //Dados do login
                    PFCookie.set('_email', this.login.email)
                    PFCookie.set('_password', this.login.password)
                    //Atribui o valor do token ao cookie _os_token
                    PFCookie.set('_pf_token', token)
                    //Ativa o cookie _online
                    PFCookie.set('_online', true)
                    //Atribui o usuário ao cookie _user
                    PFCookie.set('_username', response.data.user.name)
                    PFCookie.set('_userid', response.data.user.id)
                    PFCookie.set('_pre_approval_code', response.data.user.pre_approval_code)
                    PFCookie.set('_useraccess', response.data.user.status)

                    this.$router.go(0)
                }

            }).finally(() => {

                this.loadingShow(false)
            })
        },
        loadingShow(param)
        {
            this.loadingParam = param
        }
    }
}
</script>

<style scoped>
.login{
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.login-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.login-form{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.login-form-wrapper{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}
.login-form-wrapper button{
    margin-top: 10px;
    width: 100%;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    border: none;
    color: #fff;
}
.login-form-wrapper button:hover{
    transform: scale(1.01);
    transition: .2s;
}
.login-start{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.login-start a{
    text-decoration: none;
    color: #777;
}
.banner-login{
    width: 50%;
    height: 100vh;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner-login img{
    width: 100%;
    max-width: 300px;
}

@media screen and (max-width: 991px){
    .login{
        padding: 0 20px;
    }
    .banner-login{
        display: none;
    }
    .login-form{
        width: 100%;
    }
}
</style>