import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes/index'
import vuetify from './plugins/vuetify'
import './plugins/vuetify-mask'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({

  mode: 'history',
  routes,
})

new Vue({
  el: '#app',
  router,
  vuetify,
  render: h => h(App)
})
